import React from "react";
import Layout from "../other/layout";
import SEO from "../other/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Img from "gatsby-image";
import FadeIn from 'react-fade-in';
import { graphql, useStaticQuery } from "gatsby";
const AssociationManagementServices = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      associationManagementServices: file(relativePath: { eq: "associationManagementServices.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }      
    }
  `);
  return (
    <Layout>
      <FadeIn>
        <SEO title={intl.formatMessage({ id: "innerpage-172" })} />
        <div>
          <FadeIn delay={200} transitionDuration={700}>
            <div className="mainlyt">
              <Img fluid={data.associationManagementServices.childImageSharp.fluid}
                className="img-responsive"
              />
            </div>
          </FadeIn>
          <div>
            <div className="bodyTextCss">
              <FadeIn className="BodyContainer bodytext" delay={210} transitionDuration={500}>

                <div className="">
                  <div className="assessment">{intl.formatMessage({ id: "innerpage-172" })}</div>
                </div>

                <h6>{intl.formatMessage({ id: "innerpage-173" })}</h6>
                <p>{intl.formatMessage({ id: "innerpage-174" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-175" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-176" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-177" })}</p>
                <p>{intl.formatMessage({ id: "innerpage-178" })}</p>
                <h6>{intl.formatMessage({ id: "innerpage-179" })}</h6>
                <p>{intl.formatMessage({ id: "innerpage-180" })}</p>
                <ul>
                  <li>{intl.formatMessage({ id: "innerpage-181" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-182" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-183" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-184" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-185" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-186" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-187" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-188" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-189" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-190" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-191" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-192" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-193" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-194" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-195" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-196" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-197" })}</li>
                  <li>{intl.formatMessage({ id: "innerpage-198" })}</li>
                </ul>
              </FadeIn>
            </div>

          </div>
        </div>
      </FadeIn>
    </Layout>

  );
};

export default AssociationManagementServices;
